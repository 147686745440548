import React from 'react';
import {connect} from 'react-redux';
import {actions} from '../../../store/session';
import * as yup from 'yup';
// import {Alert, Button, Card, Col, Container, Dropdown, Form, FormControl, FormGroup, Row} from 'react-bootstrap';
import {AppService, PasswordResetService} from '../../../services';
import {t} from '../../../utils/Translator';
import {Alert, Box, Button, Container, Grid, Snackbar, TextField, Typography} from '@mui/material';
import {Col, Row} from "react-bootstrap";
import {Form, Formik} from "formik";

export class NewPasswordPage extends React.Component {
    constructor(...args) {
        super(...args);

        this.state = {error: '', sending: false, resetSuccess:false, lang: AppService.lang['name']};
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeLang = this.changeLang.bind(this);
    }

    async handleSubmit(values) {
        if (!values.password || !values.passwordVerify) return

        const token = this.props.location.state.code

        try {
            this.setState({sending: true, error: ''})
            await PasswordResetService.updatePassword(token, values.password)
            this.setState({sending: false, error: '', resetSuccess:true})
            this.props.history.push('/');
        } catch (ex) {
            this.setState({error: t('passwordResetFailed')})
            this.setState({sending: false})
        } finally {

        }
    }

    componentWillUpdate() {
    }

    changeLang(lang) {
        const params = new URLSearchParams(window.location.search);
        params.set('lang', lang);
        const host = "/";
        window.history.replaceState({}, '', `${host}?${params}`);
        window.location.reload(true);
    }


    render() {
        const {sending, error, lang, resetSuccess} = this.state;
        //const allow_registration = Server.registration; //when you are going to use register function, just use this line
        const allow_registration = false;
        const schema = yup.object({
            password: yup.string().min(4).required(),
            passwordVerify: yup.string().oneOf([yup.ref('password')]).required()
        });

        return (
            <Container fluid={true} maxWidth={false} className="h-100 w-100">
                <Snackbar
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    open={resetSuccess} autoHideDuration={4000}
                >
                    <Alert severity="success" sx={{width: '100%'}}>
                        {t("passwordResetSuccess")}
                    </Alert>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{ vertical:'top', horizontal:'right' }}
                    open={error!==null && error!==undefined && error.length>0} autoHideDuration={4000} >
                    <Alert severity="error" sx={{ width: '100%' }}>
                        {error}
                    </Alert>
                </Snackbar>
                <Row className="d-flex h-100 w-100 login-container">
                    {/*<Head>*/}
                    {/*    <title>Login | Material Kit</title>*/}
                    {/*</Head>*/}
                    <div className="login-title">
                        <h1 className="welcome">Welcome to</h1>
                        <img className="pt-2 login-logo"
                             src="img/logo-light.svg" alt="IQ track"
                        />
                        <h2 className="business">Business</h2>
                    </div>
                    <Formik
                        validationSchema={schema}
                        onSubmit={(e) => this.handleSubmit(e)}
                        disabled={sending}
                        initialValues={{}}
                    >
                        {({
                              values,
                              handleSubmit,
                              handleChange,
                              errors,
                          }) => (
                            <Form noValidate onSubmit={handleSubmit} className="form form-horizontal ">
                                    <Box
                                        className="login-card"
                                        component="main"
                                        sx={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            flexGrow: 1,
                                            minHeight: '100%',

                                            backgroundImage: ("/static/images/a.jpg")
                                        }}

                                    >
                                        <Container maxWidth="sm">

                                            <form onSubmit={this.handleSubmit}>
                                                <Box sx={{my: 3}}>
                                                    <Typography
                                                        color="textPrimary"
                                                        variant="h4"
                                                    >
                                                        {t("passwordReset")}
                                                    </Typography>
                                                    <Typography
                                                        color="textSecondary"
                                                        gutterBottom
                                                        variant="body2"
                                                    >
                                                        {t("newPassword")}
                                                    </Typography>
                                                </Box>
                                                <Grid
                                                    container
                                                    spacing={3}
                                                >

                                                </Grid>
                                                <TextField
                                                    error={Boolean(errors.password)}
                                                    fullWidth
                                                    label={t('userPassword')}
                                                    margin="normal"
                                                    name="password"
                                                    // onBlur={}
                                                    onChange={handleChange}
                                                    type="password"
                                                    value={values.password}
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    error={Boolean(errors.passwordVerify)}
                                                    fullWidth
                                                    label={t('verifyPassword')}
                                                    margin="normal"
                                                    name="passwordVerify"
                                                    // onBlur={}
                                                    onChange={handleChange}
                                                    type="password"
                                                    value={values.passwordVerify}
                                                    variant="outlined"
                                                />
                                                <Box sx={{py: 2}}>
                                                    <Button
                                                        color="primary"
                                                        disabled={sending}
                                                        fullWidth
                                                        size="large"
                                                        type="submit"
                                                        variant="contained"
                                                    >
                                                        {sending ? t('sharedLoading') : t('reset')}
                                                    </Button>
                                                </Box>
                                            </form>
                                        </Container>
                                    </Box>
                            </Form>
                        )}
                    </Formik>
                </Row>
            </Container>
        )

        //     return (
        //     <div className="page" id="pgLogin">
        //         <Container className="h-100">
        //             <Row className="h-100 d-flex justify-content-center align-items-center">
        //                 <Col className="login-form m-md-0">
        //                     <Formik
        //                         validationSchema={schema}
        //                         onSubmit={(e) => this.handleSubmit(e)}
        //                         disabled={sending}
        //                         initialValues={{}}
        //                     >
        //                         {({
        //                               handleSubmit,
        //                               handleChange,
        //                               errors,
        //                           }) => (
        //                             <Form noValidate onSubmit={handleSubmit} className="form form-horizontal ">
        //                                 <Card className="border-0 shadow-lg">
        //                                     <Card.Header className="bg-dark text-white text-center">
        //                                         <h1 className="h3 m-0"><img src="img/logo-light.svg" alt="IQ track"
        //                                                                     height="50"/></h1>
        //                                     </Card.Header>
        //                                     <Card.Body className="pb-4">
        //
        //                                         <FormGroup className="mb-3">
        //                                             <Dropdown block="true">
        //                                                 <Dropdown.Toggle block={true} variant="light">
        //                                                     {lang}
        //                                                 </Dropdown.Toggle>
        //                                                 <Dropdown.Menu className="scrollable-menu">
        //                                                     {Object.keys(languages).map(k => {
        //                                                         return <Dropdown.Item key={k}
        //                                                                               onClick={() => this.changeLang(k)}>{languages[k]['name']}</Dropdown.Item>
        //                                                     })}
        //                                                 </Dropdown.Menu>
        //                                             </Dropdown>
        //                                         </FormGroup>
        //                                         {error ? <Alert variant="danger"> {error}</Alert> : ''}
        //                                         <FormGroup className="mb-4">
        //                                             {/*<FormLabel>{intl.formatMessage({ id: 'userEmail' })}</FormLabel> */}
        //                                             <FormControl
        //                                                 placeholder={t('userEmail')}
        //                                                 type="email"
        //                                                 aria-label="Email"
        //                                                 autoComplete="current-email"
        //                                                 required
        //                                                 name="email"
        //                                                 onChange={handleChange}
        //                                                 isInvalid={!!errors.email}
        //                                             />
        //                                             <Form.Control.Feedback type="invalid">
        //                                                 {errors.email}
        //                                             </Form.Control.Feedback>
        //                                         </FormGroup>
        //                                         <FormGroup>
        //                                             {/* <FormLabel>{intl.formatMessage({ id: 'userPassword' })}</FormLabel>*/}
        //                                             <FormControl
        //                                                 placeholder={t('userPassword')}
        //                                                 aria-label="Password"
        //                                                 type="password"
        //                                                 autoComplete="current-password"
        //                                                 required
        //                                                 name="password"
        //                                                 onChange={handleChange}
        //                                                 isInvalid={!!errors.password}
        //                                             />
        //                                             <Form.Control.Feedback type="invalid">
        //                                                 {errors.password}
        //                                             </Form.Control.Feedback>
        //                                         </FormGroup>
        //                                         <FormGroup>
        //                                             <label className="custom-chk">
        //                                                 <input type="checkbox" name="remember" onChange={handleChange}/>
        //                                                 <span className="checkmark" onClick={(e) => null}></span>
        //                                                 {t('userRemember')}
        //                                             </label>
        //                                         </FormGroup>
        //                                         <div className="">
        //                                             <Button type="submit" variant="dark" className="btn-block"
        //                                                     disabled={sending}>{sending ? t('sharedLoading') : t('loginLogin')}</Button>
        //                                         </div>
        //                                         {
        //                                             allow_registration ?
        //                                                 <div className="mt-2">
        //                                                     <Link to="/register"
        //                                                           className="btn btn-outline btn-block">{t('loginRegister')}</Link>
        //                                                 </div> : null
        //                                         }
        //                                     </Card.Body>
        //                                 </Card>
        //                             </Form>
        //
        //                         )}
        //                     </Formik>
        //                 </Col>
        //             </Row>
        //         </Container>
        //     </div>
        // )
    }
}


const mapStateToProps = (state) => ({
    loading: state.session.loading,
    error: state.session.error,
    user: state.session.user,
})

const mapDispatchToProps = dispatch => ({
    dispatch,
    login: credentials => {
        dispatch(actions.login(credentials));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPasswordPage)