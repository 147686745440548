import React from "react";
import { connect } from "react-redux";
import { Button } from "@mui/material";
import { t } from "../../../utils/Translator";
import { AppService, DevicesService } from "../../../services";
import Page from "../../Page";
import DeviceForm from "./form";
import { Device } from "../../../models";
import List from "./list";
import devices from "../../../store/devices";
import Events from "../../../utils/Events";
import events from "../../../utils/Events";
import { store } from "../../../store";
import moment from "moment";
import constants from "../../../config/constants";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class DeviceListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      item: null,
      filter: null,
    };

    this.hideForm = this.hideForm.bind(this);
    this.showForm = this.showForm.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onFilteredDevices = this.onFilteredDevices.bind(this);
  }

  componentDidMount() {
    events.on("filteredDevices", this.onFilteredDevices);
  }

  componentWillUnmount() {
    events.off("filteredDevices", this.onFilteredDevices);
  }

  async deleteItem(id) {
    if (await AppService.showDeleteConfirm()) {
      DevicesService.destroy(id);
    }
  }

  showForm(id) {
    this.setState({
      showForm: true,
      item: id ? new Device().deserialize(this.props.items[id]) : new Device(),
    });
  }

  async onSave(item) {
    try {
      if (item.id) {
        await DevicesService.update(item);
        store.dispatch(devices.actions.updated(item));
        Events.emit(devices.types.UPDATED, item);
      } else {
        await DevicesService.save(item);
      }
      this.hideForm();
    } catch (ex) {
      AppService.showError(ex);
    }
  }

  hideForm() {
    this.setState({
      showForm: false,
      item: null,
    });
  }

  onFilteredDevices(filter) {
    this.setState({ filter: filter });
  }

  render() {
    const { onHide } = this.props;
    const exportData =
      this.state.filter == null
        ? Object.values(this.props.items)
        : List.filterValues(Object.values(this.props.items), this.state.filter);

    return (
      <React.Fragment>
        {this.state.showForm ? (
          <Page
            onHide={this.hideForm}
            title={
              <React.Fragment>
                <h3 className="title">
                  <i className="mdi mdi-car"></i>{" "}
                  {this.state.item.id ? t("sharedEdit") : t("sharedAdd")}{" "}
                  {t("sharedDevice")}
                </h3>
              </React.Fragment>
            }
          >
            <DeviceForm
              item={this.state.item}
              onSave={this.onSave}
              onHide={this.hideForm}
            ></DeviceForm>
          </Page>
        ) : null}

        <div className="container-wrapper grow">
          <div className="d-flex flex-column h-100 w-100 flex-grow-1 form form-sm overflow">
            <div className="flex-grow-1 overflow">
              <div className="container p-0">
                <List
                  items={this.props.items}
                  onDelete={this.deleteItem}
                  onSelect={this.showForm}
                  enableDelete={true}
                  enableSelection={false}
                />
              </div>
            </div>
            <footer className="footer">
              <div className="container d-flex">
                <div className="flex-grow-1 d-flex">
                  <Button
                    variant="contained"
                    size="large"
                    className="align-self-stretch btn-blue"
                    onClick={() => this.showForm("")}
                  >
                    <i className="mdi mdi-plus-circle-outline"></i>{" "}
                    {t("sharedAdd")}
                  </Button>
                </div>
                <ExcelFile
                  element={
                    <Button
                      variant="contained"
                      size="large"
                      className="btn-green mr-2"
                    >
                      <i className="mdi mdi-download"></i>
                      {t("reportExport")}
                    </Button>
                  }
                >
                  <ExcelSheet data={exportData} name={t("deviceTitle")}>
                    <ExcelColumn label={t("reportDevice")} value="name" />
                    <ExcelColumn label={t("sharedDisabled")} value="disabled" />
                    <ExcelColumn
                      label={t("deviceCategory")}
                      value={(col) => t("category" + col.category.ucFirst())}
                    />
                    <ExcelColumn
                      label={t("deviceIdentifier")}
                      value={(col) => t(col.getFormattedProperty("uniqueId"))}
                    />
                    <ExcelColumn
                      label={t("deviceModel")}
                      value={(col) => t(col.getFormattedProperty("model"))}
                    />
                    <ExcelColumn
                      label={t("deviceContact")}
                      value={(col) => t(col.getFormattedProperty("contact"))}
                    />
                    <ExcelColumn
                      label={t("Exp. date")}
                      value={(col) =>
                        col.attributes["expirationDate"]
                          ? moment(
                              new Date(col.attributes["expirationDate"])
                            ).format(constants.dateFormat)
                          : ""
                      }
                    />
                    <ExcelColumn
                      label={t("Reg. date")}
                      value={(col) =>
                        col.attributes["registrationDate"]
                          ? moment(
                              new Date(col.attributes["registrationDate"])
                            ).format(constants.dateFormat)
                          : ""
                      }
                    />
                    <ExcelColumn
                      label={t("Owner")}
                      value={(col) => t(col.attributes["owner"])}
                    />
                    <ExcelColumn
                      label={t("Created by")}
                      value={(col) => t(col.attributes["createdBy"])}
                    />
                    <ExcelColumn
                      label={t("Last Update")}
                      value={(col) => t(col.attributes["lastUpdate"])}
                    />
                  </ExcelSheet>
                </ExcelFile>
                <Button
                  variant="contained"
                  className="btn-red"
                  size="large"
                  onClick={onHide}
                >
                  <i className="mdi mdi-close"></i> {t("sharedCancel")}
                </Button>
              </div>
            </footer>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  items: state.devices.devices,
});

export default connect(mapStateToProps, null)(DeviceListPage);
