import axios from 'axios';
import config from '../config';

const instance = axios.create({
    baseURL: config.api_base_url,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    }
})

// Request interceptor
instance.interceptors.request.use(request => {
    const token = localStorage.getItem('token')
    if (token) {
        request.headers.common['Authorization'] = `Bearer ${token}`
    }
    /*const locale = store.getters['lang/locale']
    if (locale) {
        request.headers.common['Accept-Language'] = locale
    }*/

    //request.headers.common['X-Client'] = process.env.appClientType;
    //request.headers.common['X-Version'] = process.env.appVersion;

    return request
}, error => {
    //Vue.swal('Hello Vue world!!!');
    return Promise.reject(error);
})

export default instance;