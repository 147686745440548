import BaseService from './ApiService';
import { User } from '../models';
import { store } from '../store'
import session from '../store/session';
import events from '../utils/Events';
import Cookie from 'js-cookie';
import { encodeBase64 } from '../utils/functions';

export class SessionService extends BaseService {
    constructor() {
        super();
        this.baseUrl = '/session';
    }

    async updateUser(model) {
        const { data } = await this.api.put('/users/' + model.pk, model.serialize());
        const user = this.transformSingleResponse(data);
        store.dispatch({ type: session.types.USER_UPDATE, payload: { user } });
        events.emit(session.types.USER_UPDATE, user);
    }

    /**
     * Login the user
     * @param {email:string, password:string} credentials 
     */
    async login(credentials) {
        var params = new URLSearchParams();
        params.append('email', credentials.email);
        params.append('password', credentials.password);

        const { data } = await this.api.post(this.baseUrl, params, {
            headers: {
                'content-type': "application/x-www-form-urlencoded"
            }
        });
        const user = this.transformSingleResponse(data);

        const userSource = user.attributes["source"];

        if(userSource !== undefined && userSource !== null && userSource !== 'business') {
            Cookie.remove("user", {
                path: "/"
            });
            Cookie.remove("password", {
                path: "/"
            });
            await this.api.delete(this.baseUrl);
            throw Error("invalidSource")
        }

        store.dispatch({ type: session.types.LOGIN, payload: { user } });
        events.emit(session.types.LOGIN, { user });

        if (credentials.remember) {
            Cookie.set("user", encodeBase64(credentials.email), {
                expires: 120,
                path: '/'
            });
            Cookie.set("password", encodeBase64(credentials.password), {
                expires: 120,
                path: '/'
            });
        }

        return user;
    }

    async tokenSession(token) {
        const { data } = await this.api.get(this.baseUrl+"?token="+token, {
            headers: {
                'content-type': "application/x-www-form-urlencoded"
            }
        });
        return data
    }

    /**
     * Check if the user is logged in
     */
    async loadSession() {
        const { data } = await this.api.get(this.baseUrl);
        const user = this.transformSingleResponse(data);
        store.dispatch({ type: session.types.LOGIN, payload: { user } });
        events.emit(session.types.LOGIN, { user });
        return user;
    }

    async logout() {
        Cookie.remove("user", {
            path: "/"
        });
        Cookie.remove("password", {
            path: "/"
        });
        await this.api.delete(this.baseUrl);
        store.dispatch({ type: session.types.LOGOUT });
        events.emit(session.types.LOGOUT, null);


    }

    /**
     * Login the user
     * @param {email:string, password:string} credentials 
     */
    async register(credentials) {

        const { data } = await this.api.post('users', credentials);
        const user = this.transformSingleResponse(data);
        return user;

    }

    /**
     * Transform the session data to user
     * @param {any} data 
     */
    transformSingleResponse(data) {
        return new User().deserialize(data);
    }
}
export default new SessionService();