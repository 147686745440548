import moment from "moment";
import "moment-timezone";
import React from "react";
import { Button, FormGroup, FormLabel } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import Datetime from "react-datetime";
import { App } from "../../App";
import { ReportsService } from "../../services";
import { store } from "../../store";
import Events from "../../utils/Events";
import { t } from "../../utils/Translator";
import CustomCheckbox from "../Controls/CustomCheckBox";
import CustomSelect from "../Controls/CustomSelect";

export default class ReportsConfig extends React.Component {
  constructor(props) {
    super(props);

    let allReports = store.getState().report_types.report_types;

     if(App.App.user.email.endsWith('@delhaize.rs')) {
      allReports["custom1"] = {
        key: "custom1",
        name: t("Opšti izveštaj"),
      };
      allReports["custom2"] = {
        key: "custom2",
        name: t("Radno vreme"),
      };
     }

    const reportOptions = [];
    Object.values(allReports).map((rt) => {
      reportOptions.push({ value: rt.key, label: t(rt.name) });
    });
    const allDevices = store.getState().devices.devices;
    const deviceOptions = [];
    Object.values(allDevices).map((d) => {
      deviceOptions.push({ value: d.id, label: d.name });
    });
    deviceOptions.sort((a, b) =>
      a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
    );

    const allGroups = store.getState().groups.groups;
    const groupOptions = [];
    Object.values(allGroups)
      .filter((o) => o.id > 0)
      .map((d) => {
        groupOptions.push({ value: d.id, label: d.name });
      });

    groupOptions.sort((a, b) =>
      a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
    );

    const attributeOptions = [];
    Object.values(store.getState().position_attributes.position_attributes).map(
      (o) => {
        if (o.valueType === "number") {
          if (
            o.key !== "acceleration" &&
            o.key !== "armed" &&
            o.key !== "batteryLevel" &&
            o.key !== "battery" &&
            o.key !== "deviceTemp" &&
            o.key !== "distance" &&
            o.key !== "gps" &&
            o.key !== "hdop" &&
            o.key !== "hours" &&
            o.key !== "index" &&
            o.key !== "serviceOdometer" &&
            o.key !== "steps" &&
            o.key !== "throttle" &&
            o.key !== "totalDistance" &&
            o.key !== "tripOdometer" &&
            o.key !== "vdop" &&
            o.key !== "pdop" &&
            o.key !== "obdOdometer" &&
            o.key !== "obdSpeed" &&
            o.key !== "fuelConsumption" &&
            o.key !== "satVisible"
          )
            attributeOptions.push({
              label:
                o.key === "rssi"
                  ? t("Signal")
                  : o.key === "power"
                  ? t("positionBattery")
                  : t(o.name),
              value: o.key,
            });
        }
      }
    );
    attributeOptions.push({ label: t("positionAltitude"), value: "altitude" });
    // attributeOptions.push({ label: t('positionAccuracy'), value: 'accuracy' });
    attributeOptions.push({ label: t("positionSpeed"), value: "speed" });
    attributeOptions.push({
      label: t("alarmTemperature"),
      value: "temperature",
    });

    attributeOptions.sort((a, b) =>
      a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
    );

    const periodOptions = [
      { label: t("reportCustom"), value: "custom" },
      { label: t("reportToday"), value: "today" },
      { label: t("reportYesterday"), value: "yesterday" },
      { label: t("reportThisWeek"), value: "thisWeek" },
      { label: t("reportPreviousWeek"), value: "previousWeek" },
      { label: t("reportThisMonth"), value: "thisMonth" },
      { label: t("reportPreviousMonth"), value: "previousMonth" },
    ];

    const eventTypeOptions = [];
    const allNotificationTypes =
      store.getState().notification_types.notification_types;
    Object.values(allNotificationTypes).map((d) => {
      eventTypeOptions.push({
        value: d.type,
        label: t("event" + d.type.ucFirst()),
      });
    });

    eventTypeOptions.sort((a, b) => (a.label > b.label ? 1 : -1));
    eventTypeOptions.unshift({ label: t("eventAll"), value: "eventAll" });

    const drivers = App.Store.getState().drivers.drivers;

    const driverOptions = [];
    Object.values(drivers).map((d) =>
      driverOptions.push({ value: d.uniqueId, label: d.name })
    );
    driverOptions.sort((a, b) =>
      a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
    );

    this.state = {
      reportOptions: reportOptions,
      allReports: allReports,
      currentType: null,
      deviceOptions: deviceOptions,
      driverOptions: driverOptions,
      selectedDrivers: "",
      allDevices: allDevices,
      selectedDevices: "",
      groupOptions: groupOptions,
      allGroups: allGroups,
      selectedGroups: "",
      showMarkers: false,
      showStops: false,
      showIdle: false,
      periodOptions: periodOptions,
      selectedPeriod: "",
      eventTypeOptions: eventTypeOptions,
      selectedEventTypes: "",
      attributeOptions: attributeOptions,
      selectedChartType: null,
      period: {
        from: new Date(new Date().getTime() - 30 * 60 * 1000),
        to: new Date(),
      },
    };

    this.renderConfigOptions = this.renderConfigOptions.bind(this);
    this.changeType = this.changeType.bind(this);
    this.changeSelectedDevices = this.changeSelectedDevices.bind(this);
    this.changeSelectedDrivers = this.changeSelectedDrivers.bind(this);
    this.changeSelectedGroups = this.changeSelectedGroups.bind(this);
    this.toggleMarkers = this.toggleMarkers.bind(this);
    this.toggleStops = this.toggleStops.bind(this);
    this.toggleIdle = this.toggleIdle.bind(this);
    this.changeSelectedPeriod = this.changeSelectedPeriod.bind(this);
    this.changePeriod = this.changePeriod.bind(this);
    this.showReport = this.showReport.bind(this);
    this.changeSelectedEvents = this.changeSelectedEvents.bind(this);
    this.changeSelectedChartType = this.changeSelectedChartType.bind(this);
    this.setDeviceExternalReport = this.setDeviceExternalReport.bind(this);
  }

  componentWillMount() {
    this.addEventListeners();
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  addEventListeners() {
    Events.on("deviceExternalReport", this.setDeviceExternalReport);
  }

  removeEventListeners() {
    Events.off("deviceExternalReport", this.setDeviceExternalReport);
  }

  setDeviceExternalReport(device, report) {
    const from = new Date();
    const to = new Date();
    to.setDate(to.getDate() + 1);
    from.setHours(0, 0, 0, 0);
    to.setHours(0, 0, 0, 0);

    this.setState(
      {
        currentType: this.state.allReports[report],
        selectedDevices: device.id + "",
        selectedPeriod: "today",
        period: {
          from: from,
          to: to,
        },
      },
      () => {
        this.showReport();
      }
    );
  }

  changeSelectedDrivers(name, value) {
    this.setState({
      selectedDrivers: value,
    });
  }

  changeSelectedDevices(name, value) {
    this.setState({
      selectedDevices: value,
    });
  }

  changeSelectedGroups(name, value) {
    this.setState({
      selectedGroups: value,
    });
  }

  changeSelectedEvents(name, value) {
    if (value.includes("eventAll")) {
      value = "eventAll";
    }
    this.setState({
      selectedEventTypes: value,
    });
  }

  toggleMarkers() {
    this.setState({
      showMarkers: !this.state.showMarkers,
    });
  }

  toggleStops() {
    this.setState({
      showStops: !this.state.showStops,
    });
  }

  toggleIdle() {
    this.setState({
      showIdle: !this.state.showIdle,
    });
  }

  changeSelectedPeriod(name, value) {
    const custom = value === "custom";
    let day, first, from, to;
    if (!custom) {
      from = new Date();
      to = new Date();
      switch (value) {
        case "today":
          to.setDate(to.getDate() + 1);
          break;
        case "yesterday":
          from.setDate(to.getDate() - 1);
          break;
        case "thisWeek":
          day = from.getDay();
          first = from.getDate() - day + (day === 0 ? -6 : 1);
          from.setDate(first);
          to.setDate(first + 7);
          break;
        case "previousWeek":
          day = from.getDay();
          first = from.getDate() - day + (day === 0 ? -6 : 1);
          from.setDate(first - 7);
          to.setDate(first);
          break;
        case "thisMonth":
          from.setDate(1);
          to.setDate(1);
          to.setMonth(from.getMonth() + 1);
          break;
        case "previousMonth":
          from.setDate(1);
          from.setMonth(from.getMonth() - 1);
          to.setDate(1);
          break;
        default:
          break;
      }
      from.setHours(0, 0, 0, 0);
      to.setHours(0, 0, 0, 0);
      this.setState({
        period: {
          from: from,
          to: to,
        },
      });
    }
    this.setState({
      selectedPeriod: value,
    });
  }

  changePeriod(type, value) {
    this.setState({
      period: {
        ...this.state.period,
        [type]: value,
      },
    });
  }

  clearReport() {}

  changeSelectedChartType(type, value) {
    this.setState({
      selectedChartType: value,
    });
  }

  async showReport() {
    const {finalFrom, finalTo} = this.timeZonedFromTo()
    const filter = {
      from: finalFrom,
      to: finalTo,
      deviceId: String(this.state.selectedDevices).split(","),
      groupId: this.state.selectedGroups.split(","),
      uniqueId: String(this.state.selectedDrivers).split(","),
    };

    if (this.state.currentType.key === "events") {
      filter.type = this.state.selectedEventTypes.split(",");
      filter.type = filter.type.filter((e) => e !== "eventAll");
    }
    document.body.classList.add("show-report");
    Events.emit("reports/CLEAR");
    Events.emit("reports/SHOW", { reportType: this.state.currentType });
    Events.emit("CLOSE_SIDEBAR");
    App.App.showLoading();

    const type =
      this.state.currentType.key !== "chart"
        ? this.state.currentType.key
        : "route";
    const config = {
      showMarkers: this.state.showMarkers,
      showStops:
        type === "route" || type === "trips" || type === "driverTrips"
          ? this.state.showStops
          : undefined,
      showIdle:
        type === "route" || type === "trips" || type === "driverTrips"
          ? this.state.showIdle
          : undefined,
      chartType: this.state.selectedChartType,
    };
    try {
      if (type === "summary") {
        console.log("Filter", filter);
        const devices = filter.deviceId;
        let data = null;
        for (const device of devices) {
          console.log("fetching summary for " + device);
          const id = String(device);
          filter.deviceId = [id];
          const r = await ReportsService.getReport(type, filter, config);
          console.log("data", data, "new", r);
          if (data === null) data = r;
          else {
            data.data[id] = r.data[id];
          }
        }
        data.showMarkers = this.state.showMarkers;
        data.showStops = this.state.showStops;
        data.showIdle = this.state.showIdle;
        console.log("emitting summaries", data);
        Events.emit("reports/DATA", data);
      } else {
        //  if (type !== 'route') {
        const data = await ReportsService.getReport(type, filter, config);
        data.showMarkers = this.state.showMarkers;
        data.showStops = this.state.showStops;
        data.showIdle = this.state.showIdle;
        Events.emit("reports/DATA", data);
      }
      // else {
      //     let init = true
      //     let hasMore = false
      //     let startingId = -1
      //     let f = Object.assign({}, filter)
      //     let deviceData = {}
      //     let finalData = {}

      //     while (init === true || hasMore) {
      //         if (startingId > -1)
      //             f.startingId = startingId
      //         const r = await ReportsService.getReport(type, f, config);
      //         const d = r.data[filter.deviceId]

      //         hasMore = d && d.length && d.length === 20000
      //         startingId = hasMore ? d[d.length - 1].id : -1

      //         if (deviceData[filter.deviceId])
      //             deviceData[filter.deviceId] = deviceData[filter.deviceId].concat(d)
      //         else
      //             deviceData[filter.deviceId] = d

      //         r.data[filter.deviceId] = deviceData[filter.deviceId]

      //         finalData = r
      //         if (init) init = false;
      //     }
      //     finalData.showMarkers = this.state.showMarkers
      //     finalData.showStops = this.state.showStops
      //     finalData.showIdle = this.state.showIdle
      //     Events.emit('reports/DATA', finalData)
      // }
    } catch (ex) {
      console.log("Error loading page",ex);
      App.App.showError(ex);
    } finally {
      App.App.hideLoading();
    }
  }

  async exportReport(email) {
    const {finalFrom, finalTo} = this.timeZonedFromTo();
    const filter = {
      from: finalFrom,
      to: finalTo,
      deviceId: String(this.state.selectedDevices).split(","),
      groupId: this.state.selectedGroups.split(","),
      uniqueId: String(this.state.selectedDrivers).split(","),
      mail: email,
      type: this.state.selectedEventTypes.split(","),
    };

    console.log("FRom", filter.from);
    const type =
      this.state.currentType.key != "chart"
        ? this.state.currentType.key
        : "route";
    try {
      const data = await ReportsService.reportExel(type, filter, email);
    } catch (ex) {
      App.App.showError(ex);
    }
  }

  renderConfigOptions() {
    const {
      deviceOptions,
      groupOptions,
      driverOptions,
      selectedDrivers,
      selectedDevices,
      selectedGroups,
      showMarkers,
      showIdle,
      showStops,
      periodOptions,
      selectedPeriod,
      period,
      eventTypeOptions,
      selectedEventTypes,
      selectedChartType,
      attributeOptions,
    } = this.state;

    return (
      <React.Fragment>
        <React.Fragment>
          {this.state.currentType &&
          (this.state.currentType.key === "driverTrips" ||
            this.state.currentType.key === "driverSummary" ||
            this.state.currentType.key === "driverEcoscores") ? null : (
            <FormGroup className="m-3">
              <FormLabel>{t("deviceTitle")}</FormLabel>
              {this.state.currentType &&
              this.state.currentType.key === "route" ? (
                <CustomSelect
                  className="custom-select-margin-50"
                  options={deviceOptions}
                  valueEnabled={true}
                  defaultValue={selectedDevices}
                  onChange={this.changeSelectedDevices}
                />
              ) : (
                <CustomSelect
                  className="custom-select-margin-50"
                  isMulti
                  options={deviceOptions}
                  valueEnabled={true}
                  defaultValue={selectedDevices}
                  onChange={this.changeSelectedDevices}
                />
              )}
            </FormGroup>
          )}

          {this.state.currentType &&
          (this.state.currentType.key === "driverTrips" ||
            this.state.currentType.key === "driverSummary" ||
            this.state.currentType.key === "driverEcoscores") ? null : this
              .state.currentType && this.state.currentType.key !== "route" ? (
            <FormGroup className="m-3">
              <FormLabel>{t("settingsGroups")}</FormLabel>
              <CustomSelect
                className="custom-select-margin-50"
                isMulti
                options={groupOptions}
                defaultValue={selectedGroups}
                onChange={this.changeSelectedGroups}
              />
            </FormGroup>
          ) : null}

          {this.state.currentType && this.state.currentType.key === "events" ? (
            <FormGroup className="m-3">
              <FormLabel>{t("reportEvents")}</FormLabel>
              <CustomSelect
                className="custom-select-margin-50"
                isClearable={false}
                isMulti
                options={eventTypeOptions}
                defaultValue={selectedEventTypes}
                onChange={this.changeSelectedEvents}
              />
            </FormGroup>
          ) : null}

          {this.state.currentType && this.state.currentType.key === "chart" ? (
            <FormGroup className="m-3">
              <FormLabel>{t("reportChartType")}</FormLabel>
              <CustomSelect
                className="custom-select-margin-50"
                options={attributeOptions}
                defaultValue={selectedChartType}
                onChange={this.changeSelectedChartType}
              />
            </FormGroup>
          ) : null}

          {this.state.currentType &&
          (this.state.currentType.key === "driverTrips" ||
            this.state.currentType.key === "driverSummary" ||
            this.state.currentType.key === "driverEcoscores") ? (
            <FormGroup className="m-3">
              <FormLabel>{t("sharedDriver")}</FormLabel>
              <CustomSelect
                className="custom-select-margin-50"
                options={driverOptions}
                isClearable
                defaultValue={selectedDrivers}
                onChange={this.changeSelectedDrivers}
              />
            </FormGroup>
          ) : null}
          {this.state.currentType &&
          (this.state.currentType.key === "route" ||
            this.state.currentType.key === "trips" ||
            this.state.currentType.key === "driverTrips") ? (
            <FormGroup className="ml-3 mt-2 mb-0">
              <CustomCheckbox
                defaultChecked={showStops}
                onChange={this.toggleStops}
                label={t("showStops")}
              />
            </FormGroup>
          ) : null}

          {this.state.currentType &&
          (this.state.currentType.key === "route" ||
            this.state.currentType.key === "trips" ||
            this.state.currentType.key === "driverTrips") ? (
            <FormGroup className="ml-3 mt-0 mb-0">
              <CustomCheckbox
                defaultChecked={showIdle}
                onChange={this.toggleIdle}
                label={t("showIdle")}
              />
            </FormGroup>
          ) : null}

          {/*<FormGroup className="m-3">*/}
          {/*    <CustomCheckbox defaultChecked={showMarkers} onChange={this.toggleMarkers} label={t('reportShowMarkers')} />*/}
          {/*</FormGroup>*/}
          {this.state.currentType.key === "custom2" ? null:
          <FormGroup className="m-3">
            <FormLabel>{t("reportPeriod")}</FormLabel>
            <CustomSelect
              className="custom-select-margin-50"
              isClearable={false}
              options={periodOptions}
              defaultValue={selectedPeriod}
              valueEnabled={true}
              onChange={this.changeSelectedPeriod}
            />
          </FormGroup>
          }
          {selectedPeriod === "custom" ? (
            <React.Fragment>
              <div className="period">
                <FormGroup className="m-3">
                  <FormLabel>{t("reportFrom")}</FormLabel>
                  <Datetime
                    timeFormat={"HH:mm"}
                    dateFormat="YYYY-MM-DD"
                    defaultValue={period.from}
                    onChange={(e) => this.changePeriod("from", e)}
                  >
                    {" "}
                  </Datetime>
                </FormGroup>
                <FormGroup className="m-3">
                  <FormLabel>{t("reportTo")}</FormLabel>
                  <Datetime
                    timeFormat={"HH:mm"}
                    dateFormat="YYYY-MM-DD"
                    defaultValue={period.to}
                    onChange={(e) => this.changePeriod("to", e)}
                  >
                    {" "}
                  </Datetime>
                </FormGroup>
              </div>
            </React.Fragment>
          ) : null}
          <div className="m-3">
            <Button
              className="btn-block mr-0 ml-0"
              size="sm"
              disabled={
                !selectedPeriod ||
                !this.state.currentType ||
                this.state.currentType.key === "custom1" ||
                this.state.currentType.key === "custom2" ||
                ((this.state.currentType.key === "driverTrips" ||
                  this.state.currentType.key === "driverSummary" ||
                  this.state.currentType.key === "driverEcoscores") &&
                  !selectedDrivers) ||
                (this.state.currentType.key !== "driverTrips" &&
                  this.state.currentType.key !== "driverSummary" &&
                  this.state.currentType.key !== "driverEcoscores" &&
                  !selectedDevices &&
                  !selectedGroups) ||
                (this.state.currentType.key === "chart" &&
                  !this.state.selectedChartType)
              }
              onClick={this.showReport}
              variant="primary"
            >
              {t("reportShow")}
            </Button>

            {this.state.currentType.key !== "chart" ? (
              <Button
                className="btn-block btn-green mr-0 ml-0"
                size="sm"
                disabled={
                  (!selectedPeriod && this.state.currentType.key !== "custom2") ||
                  !this.state.currentType ||
                  ((this.state.currentType.key === "driverTrips" ||
                    this.state.currentType.key === "driverSummary" ||
                    this.state.currentType.key === "driverEcoscores") &&
                    !selectedDrivers) ||
                  (this.state.currentType.key !== "driverTrips" &&
                    this.state.currentType.key !== "driverSummary" &&
                    this.state.currentType.key !== "driverEcoscores" &&
                    !selectedDevices &&
                    !selectedGroups)
                }
                onClick={() => this.exportReport(false)}
                variant="secondary"
              >
                {t("reportExport")}
              </Button>
            ) : null}
            {this.state.currentType.key !== "chart" ? (
              <Button
                className="btn-block btn-green mr-0 ml-0"
                size="sm"
                disabled={
                  !selectedPeriod ||
                  !this.state.currentType ||
                  ((this.state.currentType.key === "driverTrips" ||
                    this.state.currentType.key === "driverSummary" ||
                    this.state.currentType.key === "driverEcoscores") &&
                    !selectedDrivers) ||
                  (this.state.currentType.key !== "driverTrips" &&
                    this.state.currentType.key !== "driverSummary" &&
                    this.state.currentType.key !== "driverEcoscores" &&
                    !selectedDevices &&
                    !selectedGroups)
                }
                onClick={() => this.exportReport(true)}
                variant="secondary"
              >
                {t("reportEmail")}
              </Button>
            ) : null}
            {this.state.showingReport ? (
              <Button
                className="btn-block mr-0 ml-0"
                size="sm"
                onClick={this.clearReport}
                variant="danger"
              >
                {t("reportClear")}
              </Button>
            ) : null}
          </div>
        </React.Fragment>
      </React.Fragment>
    );
  }

  changeType(name, value) {
    let devices = this.state.selectedDevices;
    if (value === "route") {
      if (devices) {
        try {
          devices = devices.split(",")[0];
        } catch (e) {}
      }
    }

    const reports = this.state.allReports[value];

    this.setState({
      currentType: reports,
      selectedDevices: devices + "",
    });
  }

  render() {
    const { currentType, reportOptions } = this.state;
    return (
      <React.Fragment>
        <div className="filter border-bottom">
          <FormGroup className="m-3">
            <FormLabel>{t("sharedType")}</FormLabel>
            <CustomSelect
              className="custom-select-margin-50"
              options={reportOptions}
              valueEnabled={true}
              defaultValue={currentType ? currentType.key : null}
              onChange={this.changeType}
            />
          </FormGroup>
        </div>
        <div className="content reports-config">
          <Scrollbars>
            {currentType ? this.renderConfigOptions() : null}
          </Scrollbars>
        </div>
      </React.Fragment>
    );
  }

  timeZonedFromTo() {
    let timeZone = App.App.user.attributes["timezone"];

    let finalFrom;
    let finalTo;

    const format = "YYYY-MM-DDTHH:mm:ssZ";
    if (timeZone && timeZone.trim().length !== 0) {
      console.log("using timezone", timeZone)
      try {
        finalFrom = moment(this.state.period.from)
          .tz(timeZone, true)
          .format(format);
      } catch (e) {
        console.log("Timezone Error",e);
        finalFrom = moment(this.state.period.from).format(format);
      }
      try {
        finalTo = moment(this.state.period.to)
          .tz(timeZone, true)
          .format(format);
      } catch (e) {
        console.log("Timezone Error",e);
        finalTo = moment(this.state.period.to).format(format);
      }
    } else {
      finalFrom = moment(this.state.period.from).format(format);
      finalTo = moment(this.state.period.to).format(format);
    }

    return { finalFrom, finalTo };
  }
}
